span.file-icon {
  /* cursor: pointer; until something happens*/
  cursor: default;
  padding: 6px 11px 8px 11px;
  margin-left: -11px;
  border-radius: 4px;
  transition: all 0.2s;
  display: block;
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 148px;
}
span.file-icon:hover {
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(248, 248, 248, 0.301); */
}

.file-icon > img {
  margin-right: 5px;
}

div.close-icon {
  width: 26px;
  height: 26px;
  background-size: "contain";
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/static/media/close-option.f7f049cc.svg);
  margin-top: 22px;
  transform: translateY(-22px);
  transition: all 0.2s;
}

div.close-icon:hover {
  width: 26px;
  height: 26px;
  background-size: "contain";
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/static/media/close-option.f7f049cc.svg);
  margin-top: 22px;
  transform: translateY(-22px);
  transition: all 0.2s;
  cursor: pointer;
}
span.status-dot {
  margin-right: 3px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
}

span.status-dot.in-progress {
  background-color: rgb(252,189,0);
}

span.status-dot.approved,
span.status-dot.accepted {
  background-color: #70D44B;
}

span.status-dot.rejected {
  background-color: #EB4235;
}

span.status-dot.canceled {
  background-color: lightgrey;
}

span.status-dot.closed {
  background-color: #D0D7DD;
}
span.status-dot.completed {
  background-color: #57c3dd;
}
span.status-dot.new {
  background-color: #70D44B;
}

span.status-dot.pending {
  background-color: #FCBD00;
}

span.status-dot.submitted {
  width: 9px;
  height: 9px;
  background-color: white;
  border: 2px solid rgb(129, 236, 152);
}

a:visited{
  color:#323C47;
}
body {
  overflow-x: hidden;
}
div.progress-icon {
  width: 26px;
  height: 26px;
  background-size: "contain";
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/static/media/empty-circle.e4098ee1.svg);
  margin-top: 20px;
  transform: translateY(-22px);
  transition: all 0.2s;
}
div.progress-icon::after {
  content: "";
  height: 25px;
  width: 2px;
  display: block;
  position: relative;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f2f2f2;
}
div.progress-icon.checked {
  background-image: url(/static/media/check-mark-outlined.49ec667d.svg);
}
div.progress-icon.checked::after {
  background-color: #3eb7f9;
}

div.progress-icon.active {
  background-image: url(/static/media/right-arrow-solid.0adfd890.svg);
}

div.progress-icon.last {
  margin-bottom: 25px;
}

div.progress-icon.last::after {
  height: 0;
}

div.progress-sec-name {
  text-align: left;
  width: 201px;
  opacity: 0.9;
  color: #d0d7dd;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  /*padding-top: 6px;*/
  padding-bottom: 24px;
  margin: 0;
  transition: all 0.3s;
  cursor: pointer;
  margin-top: 2px;
}
div.progress-sec-name.active {
  color: #323c47;
}

/* Progress Bar Body */
div.progress-bar {
  width: 70%;
  height: 11px;
  border-radius: 17px;
  background-color: #e9eff4;
  margin: auto;
  padding-right: 20px;
}

/* Progress Bar Color Fill*/
div.progress-bar::before {
  content: "";
  display: block;
  height: 11px;
  border-radius: 17px;
  background-color: #3eb7f9;
  transition: all 1.5s;
}

/* Progress Bar Number Value*/
div.progress-bar::after {
  color: #3eb7f9;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  display: block;
  position: relative;
  right: -115%;
  top: -11px;
  transition: all 1.5s;
}

/* Progress Bar % Values */
/* 0% */
div.progress-bar.p0::before {
  width: 0%;
}
div.progress-bar.p0::after {
  content: "0%";
}

/* 20% */
div.progress-bar.p20::before {
  width: 20%;
}
div.progress-bar.p20::after {
  content: "20%";
}

/* 40% */
div.progress-bar.p40::before {
  width: 40%;
}
div.progress-bar.p40::after {
  content: "40%";
}

/* 60% */
div.progress-bar.p60::before {
  width: 65%;
}
div.progress-bar.p60::after {
  content: "60%";
}

/* 80% */
div.progress-bar.p80::before {
  width: 85%;
}
div.progress-bar.p80::after {
  content: "80%";
}

/* 100% */
div.progress-bar.p100::before {
  width: 103.5%;
}
div.progress-bar.p100::after {
  content: "100%";
}

/* Makind label of "Last" name dissapear */
div.label-gone .css-jigfdj {
  opacity: 0;
}

.plus-circle-icon {
  width: 18px;
  cursor: pointer;
}

div.add-hover {
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.2s;
}

div.add-hover:hover {
  opacity: 1;
}

button {
  float: right;
}

/* button:nth-child(2) {
  margin-right: 15px;
} */

span.ticker {
  opacity: 0;
  margin-top: 15px;
  transition: all 0.3s;
}
span.ticker input{
  text-transform: uppercase;
  font-weight: 500;
  width: 50%;
}

span.ticker input::placeholder{
  text-transform: capitalize;
}
span.ticker.is-visible {
  opacity: 1;
}
span.ticker input {
  border-style: dashed;
}

textarea::placeholder {
  color: #d3d8de;
}

.users-divider::after {
  content: "";
  height: 1px;
  width: 100%;
  display: inline-block;
  position: relative;
  top: 19px;
  left: 50%;
  transform: translateX(-49%);
  background-color: #cfd7dd;
  margin-bottom: 30px;
}

.react-tagsinput {
    width: 100%;
    border: 1px solid #CFD7DE;
    border-radius: 5px;
    background-color: #FFFFFF;
    text-align: initial;
    padding: 5px;
    overflow: hidden;
}

.react-tagsinput--focused {
    border-color: #CFD7DE;
}

.react-tagsinput-tag {
    display: inline-block;
    border: 1px solid #D0D7DD;
    border-radius: 20px;
    background-color: #FFFFFF;
    padding: 6px 10px;
    margin: 5px;
}


.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " \D7";
}

.react-tagsinput-input{
    box-sizing: border-box;	height: 36px;
    width: 100%;
    border: none;
    background-color: transparent;
}

.react-tagsinput-input::placeholder{
    color: #A3AFB7;
}

.react-tagsinput-input:focus{
    outline: none;
}

div.active > .css-moa28j {
    background-color: #36AEF8;
}
a{
    text-decoration: none;
}
/* Detail Table */
table.details {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
}

table.details td,
table.details th {
    border: 1px solid rgb(241, 241, 241);
    border-top: none;
    border-left: none;
    padding: 25px;
}

table.details tr:nth-child(even) {
    /* background-color: #f2f2f2; */
}

table.details th {
    color: black;
    width: 28%;
    font-weight: 600;
    font-size: 1.4rem;
}

table.details th:nth-child(even) {
    /* background-color: #fafafa; */
}

/*Alliance Create/Edit*/
span.btn-invite button {
    color: #3eb7f9;
    border: 1px solid #3eb7f9;
    transform: translateY(25px);
    font-size: 1.4rem;
    padding: 0 2.5rem;
}

div.divider {
    margin: 55px 0px 0px 0px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 0px;
}

div.spacing {
    margin-top: 10px; 
}

/* Company Details/Edit */
.company-icons {
    position: absolute;
    right: 10px;
    display: flex;
}

.company-icons button {
    padding: 0 2rem;
    margin: 0px 5px;
}

div.company-icons #btn-leave-company button:hover {
    color: rgb(224, 57, 57) !important;
    border-color: rgb(224, 57, 57) !important;
}

div.company-icons #btn-approve button:hover {
    color: rgb(49, 192, 92) !important;
    border-color: rgb(49, 192, 92) !important;
}

/* Alliance List */

a.item-name {
    color: #3EB7F9;
}

a.item-name:hover {
    color: #3EB7F9;
}

a.item-name:visited {
    color:#3EB7F9;
}

div.clickable-label {
  height: 100%;
}
div.clickable-label .clickable-icon {
  opacity: 0.4;
  transition: opacity 0.2s;
}
div.clickable-label:hover .clickable-icon {
  opacity: 1;
}

div.comment-label {
  height: 20%;
}
div.comment-label {
  padding-left: 99%;
  margin-top: -5px;
}
div.comment-label img {
  margin-top: 5px;
  width: 12px;
  opacity: 0.4;
  transition: all 0.2s;
  margin-right: 0px;
}

.pagination-wrapper {
    height: 100%;
    width: auto;
    background: white;
}
.pagination {
    position: relative;
    text-align: center;
    top: 20%;
}
.pagination span{
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 10px;
}
.pagination span.active:before{
    background: #3498db;
}
.pagination span:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    background: white;
    border-radius: 50%;
    border: 1px solid #3498db;

}

.pagination span.active{
    border: none!important;
}
/* initiative main create */
/* main */
.wrapperForm {
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
  }
  .mainForm {
    width: 70% !important;
    height: 70vh !important; 
    overflow: auto !important;
  }
  .sidebarForm {
    width: 30%;
    height: 45vh !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  
  /* footer */
.footerCard{
  display:flex ;
  flex-direction: row ;
  justify-content: space-between ;
}

.footerCardRight{
  display:flex ;
  flex-direction: row ;
  justify-content: space-around; 
}

  /* fileInput */
  .fileInput {
    color: #E9EFF4 !important;
    font-weight: lighter;
    background-color: white;
  }

  /* businessCaseForm */
  /* Costs & Revenue
  margin */
  .widthField{
    width: 50% !important;
  }
  .spaceForm{
    margin-top: 8px !important;
  }

  /* progressLeft */

  @media( max-width: 1100px) {
    .sizeProgress {
      font-size: 10px !important;
      padding: 10px !important;
    }
  }

.opacityButton{
  opacity: 0.7;
}
.fontWeighButton{
  font-weight: bold !important;
}

.react-tagsinput {
    width: 100%;
    border: 1px solid #CFD7DE;
    border-radius: 5px;
    background-color: #FFFFFF;
    text-align: initial;
    padding: 5px;
    overflow: hidden;
}

.react-tagsinput--focused {
    border-color: #CFD7DE;
}

.react-tagsinput-tag {
    display: inline-block;
    border: 1px solid #D0D7DD;
    border-radius: 20px;
    background-color: #FFFFFF;
    padding: 6px 10px;
    margin: 5px;
}


.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " \D7";
}

/*.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 80px;
}*/

.react-tagsinput-input{
    box-sizing: border-box;	height: 36px;
    width: 100%;
    border: none;
    background-color: transparent;
}


.react-tagsinput-input::placeholder{
    color: #A3AFB7;
}
.react-tagsinput-input:focus{
    outline: none;
}

/* Containers */
.Toastify__toast-container {
  width: 400px;
  z-index: 99999;
}

.Toastify__toast--info {
  background: #f5faff;
  border: 1px solid #4da1ff;
  border-radius: 4px;
}

.Toastify__toast--success {
  background: #f5ffdc;
  border: 1px solid #a6e50f;
  border-radius: 4px;
}

.Toastify__toast--warning {
  background: #fff9e2;
  border: 1px solid #f7cb18;
  border-radius: 4px;
}

.Toastify__toast--error {
  background: #fff0ed;
  border: 1px solid #ff6d4a;
  border-radius: 4px;
}

/* Close buttons */

.Toastify__close-button {
  align-self: center;
}

.Toastify__close-button--success {
  color: #a6e50f;
}

.Toastify__close-button--info {
  color: #4da1ff;
}

.Toastify__close-button--warning {
  color: #f7cb18;
}

.Toastify__close-button--error {
  color: #ff6d4a;
}

.StripeElement {
  width: 100%;
  border: 1px solid #d0d7dd;
  border-radius: 5px;
  padding: 8px;
  height: 36px;
}

/* #root {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
} */
body {
    background-color: rgb(244, 245, 246);
    font-family: Poppins;
}

.auth0-lock.auth0-lock .auth0-lock-content-wrapper {
    overflow: visible !important;
}

.fsp-picker,
.fsp-picker * {
    transition: none;
}

.fsp-picker-appear-active {
    transition: none;
    opacity: 0;
    display: none;
}

.fsp-picker-appear-to {
    opacity: 0;
    display: none;
}

.fsp-picker-appear {
    opacity: 0;
    display: none;
}

.spacing-td {
    width: 18%;
}

.w-150 {
    width: 150px !important;
}

.float-right {
    position: absolute;
    right: 0;
    margin-right: 2rem;
}

.LoaderMain {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loaderSideBar {
    width: 100%;
    height: 70%;
    overflow: hidden;
    display: flex;
    align-self: center;
    align-content: center;
    align-items: center;
}

.related-table {
    height: 100px;
    overflow-y: scroll;
    position: relative;
}

.justify-center-column div {
    justify-content: center !important;
}

.justify-center-row {
    justify-content: center !important;
}

.positionBottonRight {
    float: right;
}

.positionBottonClose {
    right: 15px;
    position: absolute;
}

.heightBodyCard {
    min-height: 250px;
    max-height: 55vh;
}

.minHeightCard {
    height: 94%;
}

.button-list-view {
    position: absolute;
    bottom: 3rem;
    left: 48%;
    margin-left: -50px;
    opacity: 1;
}

.radius {
    border-radius: 50px;
}

.company-icons {
    display: flex;
}

.top-icons {
    display: flex;
}

.more-icon {
    color: #7e828b;
}

.button-top {
    top: 11px;
}

.action-button-top {
    color: #878c93 !important;
    border-color: transparent !important;
}

.action-link-button {
    position: absolute;
    bottom: 2rem;
    opacity: 1;
    border-radius: 50px;
    left: 47%;
    margin-left: -52px;
}

.collaborate-button {
    border-color: transparent !important;
}

.collaborate-button-text {
    font-weight: normal;
    color: #878c93;
    font-size: 14px;
}

.card-body {
    min-height: 54vh;
    max-height: 54vh;
    padding: 0px;
    text-align: center;
}

.card-body-report {
    min-height: 66vh;
    padding: 0px;
    text-align: center;
    max-height: 66vh;
}

.card-body-report-with-footer {
    min-height: 61vh;
    padding: 0px;
    text-align: center;
    max-height: 61vh;
}

.card-body-principal {
    min-height: 63vh;
    max-height: 63vh;
    padding: 0px !important;
    text-align: center;
    margin-bottom: 0px;
    overflow: hidden !important;
}

.card-body-deal {
    min-height: 77vh;
    max-height: 77vh;
    padding: 0px !important;
    text-align: center;
    margin-bottom: 0px;
    overflow-y: hidden !important;
}

.card-body-list {
    min-height: 54vh;
    max-height: 54vh;
    padding: 0px !important;
    text-align: center;
    padding-bottom: 100px !important;
    display: table-row !important;
    overflow-x: hidden !important;
}

.card-body-full {
    min-height: 54vh;
    max-height: 58vh;
    padding: 0px !important;
    text-align: center;
    padding-bottom: 100px !important;
    display: table-row !important;
    overflow-x: hidden !important;
}

.card-body-detail {
    min-height: 54vh;
    max-height: 54vh;
    padding: 0px !important;
    text-align: center;
    margin-bottom: 70px;
}

.deal-list-view {
    width: 84.6vw;
}

.list-box {
    margin-right: 5px;
}

.onBoardingItem {
    border: 1px solid #fff;
    height: 105px;
    padding-top: 10px;
    width: 385px;
    margin-bottom: 5px;
    margin-left: 4px;
    margin-right: 4px;
}

.onboarding-name {
    color: #3EB7F9;
    font-size: 16px;
    font-weight: 700;
}

.onboarding-name:visited {
    color: #3EB7F9;

}

.onboarding-text {
    font-size: 16px;
}

.pie-legend {
    margin-left: 37px;
}

.pie-legend-text {
    font-size: 16px;
    padding-left: 10px;
    font-weight: 500;
}

.pie-legend span {
    width: 12px !important;
    height: 12px !important;
    margin-right: -10px;
}

.pie-title {
    font-size: 22px;
    font-weight: 500;
    margin-left: 10px !important;
    text-align: center;
}

.pie-graph {
    height: 250px;
    width: 400px;
}

.items-card {
    width: 84.5vw !important;
    overflow-x: hidden;
}

.items-table {
    overflow-x: scroll;
    padding: 0 !important;
}

.search-input input {
    width: 455px;
}

.search-input img {
    margin-right: 15px;
}


/* Item Tables Design  */

@media (max-height: 850px) and (width: 1400px) {
    .items-card {
        width: 79.5vw !important;
        overflow: hidden;

    }
}

@media (min-height: 850px) and (width: 1400px) {
    .items-card {
        width: 78.9vw !important;
        overflow: hidden;

    }

    .pie-graph {
        height: 250px !important;
        width: 350px !important;
    }
}

@media (min-height: 750px) and (width: 1440px) {
    .items-card {
        width: 79.4vw !important;
        overflow-x: hidden;

    }

    .pie-graph {
        height: 200px;
        width: 350px;
    }
}

@media (min-height: 800px) and (width: 1600px) {
    .items-card {
        width: 82.3vw !important;
        overflow: hidden;

    }
}

@media (min-height: 789px) and (width: 1600px) {
    .items-card {
        width: 81.5vw !important;
        overflow: hidden;

    }
}

@media (min-height: 900px) and (width: 1680px) {
    .items-card {
        width: 82.4vw !important;
        overflow: hidden;

    }
}

@media (max-height: 900px) and (width: 1366px) {
    .card-body-deal {
        min-height: 74vh;
        max-height: 74vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
    }

    .card-body-list {
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
        overflow-y: scroll !important;
    }

    .deal-list-view {
        width: 79.2vw;
    }

    .pie-legend {
        width: 20vw;
        margin-left: 3px;
    }

    .pie-legend-text {
        font-size: 15px;
        font-weight: 500;
    }

    .pie-legend span {
        width: 12px !important;
        height: 12px !important;
        margin-right: 1px !important;
    }

    .pie-graph {
        height: 200px !important;
        width: 350px !important;
    }

    .items-card {
        width: 81.5vw !important;
        overflow: hidden;

    }

    .items-table {
        overflow: auto;
        overflow-y: hidden !important;
        height: 64vh;
    }
}

@media (max-height: 768px) and (min-width: 1360px) {
    .card-body-list {
        min-height: 50vh !important;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }
}

@media (max-height: 660px) and (min-width: 1360px) {
    .card-body-deal {
        min-height: 68vh;
        max-height: 68vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
    }

    .deal-list-view {
        width: 78vw;
    }

    .card-body-list {
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
        overflow-y: auto !important;
    }

    .items-card {
        width: 78.3vw !important;
        height: 80vh;
        overflow: hidden;
    }

    .items-table {
        overflow: auto;
        overflow-y: hidden !important;
        height: 69vh;
    }

}

@media (max-height: 700px) and (width: 1280px) {
    .items-card {
        width: 76.8vw !important;
        height: 80vh;
        overflow: hidden;
    }
}

@media (max-width: 1280px) {
    .items-card {
        width: 76.9vw !important;
        overflow: hidden;
    }

    .card-body-list {
        min-height: 59vh;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }

}

@media (max-width: 1280px) and (min-height: 650px) {
    .card-body-list {
        min-height: 50vh;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }
}

@media (max-width: 1280px) and (min-height: 800px) {

    .items-card {
        width: 76.9vw !important;
        overflow: hidden;
    }

    .card-body-list {
        min-height: 56.8vh;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }

    .pie-graph {
        height: 250px !important;
        width: 330px !important;
    }

    .pie-legend-text {
        font-size: 13px !important;
    }

}

@media (max-width: 1200px) and (max-height: 760px) {
    .items-card {
        width: 74.3vw !important;
        overflow: hidden;
    }
}

@media (max-width: 1024px) and (max-height: 660px) {
    .items-card {
        width: 71.2vw !important;
        overflow: hidden;
    }
}

@media (max-height: 960px) {
    .deal-list-view {
        width: 79.5vw;
    }

    .card-body-list {
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
        overflow-y: scroll !important;
        min-height: 43vh;
    }

    .pie-legend {
        margin-left: 10px !important;
    }

    .pie-legend-text {
        font-size: 15px;
        font-weight: 500;
    }

    .pie-legend span {
        width: 10px !important;
        height: 10px !important;

    }


    .items-table {
        overflow: auto;
        overflow-y: hidden !important;
        height: 64vh;
    }

}

@media (max-height: 768px) {
    .card-body-deal {
        min-height: 70vh;
        max-height: 70vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
    }

    .deal-list-view {
        width: 79vw;
    }

    .onboarding-name {
        color: #3EB7F9;
        font-size: 15px;
        font-weight: 700;
    }

    .onboarding-name:visited {
        color: #3EB7F9;

    }

    .onboarding-text {
        font-size: 14px;
    }

    .pie-legend {
        margin-left: 5px !important;
    }

    .pie-legend-text {
        font-size: 12px;
        padding-left: 0px;
        font-weight: 500;
    }

    .pie-legend span {
        width: 8px !important;
        height: 8px !important;
        margin-right: 1px !important;
    }

    .pie-graph {
        height: 180px !important;
        width: 330px !important;
    }

    .table-cell {
        min-width: 120px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: clip !important;
    }

    .items-card {
        height: 80vh;
        overflow: hidden;
    }

    .items-table {
        overflow: auto;
        overflow-y: hidden !important;
        height: 60vh;
    }

}

@media (max-height: 650px) {
    .deal-list-view {
        width: 76.4vw;
    }

    .items-card {
        width: 76.9vw !important;
        height: 80vh;
        overflow-x: hidden;
    }

    .items-table {
        overflow: auto;
        overflow-y: hidden !important;
        height: 69vh;
    }
}

@media (min-height: 610px) {
    .card-body {
        min-height: 54vh;
        text-align: center;
        max-height: 54vh;
    }

    .card-body-report {
        min-height: 75vh;
        text-align: center;
        max-height: 75vh;
    }

    .card-body-report-with-footer {
        min-height: 70vh;
        text-align: center;
        max-height: 70vh;
    }

    .card-body-detail {
        min-height: 60vh;
        text-align: center;
    }

    .card-body-principal {
        min-height: 62vh;
        max-height: 62vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
        overflow: hidden !important;
    }


    .button-list-view {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        margin-left: -50px;
        opacity: 1;
    }
}

.pg-viewer-wrapper {
    height: 430px;
}

.icon-download {
    position: absolute;
    top: 10px;
    right: 36px;
}

.file-icon {
    height: 45px;
    display: flex;
    padding: 6px;
    width: 214px;
    border: 1px solid #e9eff4;
    margin-top: 5px;
}

.file-icon:hover {
    background-color: lightgrey;
}

@media (min-height: 700px) and (min-width: 720px) {
    .card-body {
        min-height: 60vh;
        text-align: center;
        max-height: 60vh;
    }

    .card-body-report {
        min-height: 68vh;
        text-align: center;
        max-height: 68vh;
    }

    .card-body-report-with-footer {
        min-height: 63vh;
        text-align: center;
        max-height: 63vh;
    }

    .card-body-detail {
        min-height: 62vh;
        text-align: center;
    }

    .card-body-principal {
        min-height: 68vh;
        max-height: 68vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
        overflow: hidden !important;
    }

    .card-body-list {
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 40px !important;
    }

    .heightBodyCard {
        min-height: 59vh;
        max-height: 59vh;
    }

    .button-list-view {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        margin-left: -50px;
        opacity: 1;
    }
}

@media (min-height: 768px) and (min-width: 1360px) {
    .card-body {
        min-height: 62vh;
        text-align: center;
        max-height: 62vh;
    }

    .card-body-report {
        min-height: 70vh;
        text-align: center;
        max-height: 70vh;
    }

    .card-body-report-with-footer {
        min-height: 65vh;
        text-align: center;
        max-height: 65vh;
    }

    .card-body-detail {
        min-height: 64vh;
        text-align: center;
    }

    .card-body-principal {
        min-height: 73vh;
        max-height: 73vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
        overflow: hidden !important;
    }

    .card-body-list {
        min-height: 56vh;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }

    .heightBodyCard {
        min-height: 62vh;
        max-height: 62vh;
    }

    .button-list-view {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        margin-left: -50px;
        opacity: 1;
    }

}

@media (min-height: 800px)  and (min-width: 1360px) {
    .card-body {
        min-height: 65vh;
        text-align: center;
        max-height: 65vh;
    }

    .card-body-report {
        min-height: 68vh;
        text-align: center;
        max-height: 68vh;
    }

    .card-body-report-with-footer {
        min-height: 59vh;
        text-align: center;
        max-height: 61vh;
    }

    .card-body-detail {
        min-height: 64vh;
        text-align: center;
    }

    .card-body-principal {
        min-height: 72vh;
        max-height: 73vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
        overflow: hidden !important;
    }

    .card-body-list {
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }

    .heightBodyCard {
        min-height: 62vh;
        max-height: 62vh;
    }

    .button-list-view {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        margin-left: -50px;
        opacity: 1;
    }
}

@media (min-height: 900px)  and (min-width: 1360px) {
    .card-body {
        min-height: 67vh;
        text-align: center;
        max-height: 67vh;
    }

    .card-body-report {
        min-height: 75vh;
        text-align: center;
        max-height: 75vh;
    }

    .card-body-report-with-footer {
        min-height: 69vh;
        text-align: center;
        max-height: 69vh;
    }

    .card-body-detail {
        min-height: 69vh;
        text-align: center;
    }

    .card-body-list {
        min-height: 57.1vh;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }

    .card-body-principal {
        min-height: 77vh;
        max-height: 77vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
        overflow: hidden !important;
    }

    .heightBodyCard {
        min-height: 68vh;
        max-height: 68vh;
    }
}


@media (min-height: 1200px) {
    .card-body {
        min-height: 75vh;
        text-align: center;
        max-height: 75vh;
    }

    .card-body-report {
        min-height: 83vh;
        text-align: center;
        max-height: 83vh;
    }

    .card-body-report-with-footer {
        min-height: 78vh;
        text-align: center;
        max-height: 78vh;
    }

    .card-body-detail {
        min-height: 77vh;
        text-align: center;
    }

    .card-body-principal {
        min-height: 81vh;
        max-height: 81vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
        overflow: hidden !important;
    }

    .card-body-list {
        min-height: 78vh;
        max-height: 78vh;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 150px !important;
    }

    .heightBodyCard {
        min-height: 74vh;
        max-height: 74vh;
    }
}

.cvs-input {
    margin: 0.5em;
}

.cvs-error {
    border: 1px solid red;
    border-radius: 5px;
}

.CommentInfoUser {
    position: absolute;
    top: 15px;
    left: 65px;
}

.CommentOwnerText {
    text-align: right;
}

.CommentText {
    max-width: 240px;
    min-height: 20px;
    height: auto;
    min-width: 80px;
    display: inline-block;
    background-color: #eceff1;
    padding: 6px 12px;
    padding-bottom: 0px;
    border-radius: 5px;
    color: #47525d;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    margin-top: 12px;
}


.pie-legend span {
    width: 18px !important;
    height: 18px !important;
    margin-top: 1px;
    margin-right: -20px;
}


.companyHeaderButton:hover {
    background-color: #f3f3f3;
}

.table-header-cell {
    color: #7e828b;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 18px;
    text-transform: capitalize;
    background-color: #f4f7f9;
}

.gray-border {
    border: 2px solid #f4f7f9;
}

.table-select {
    cursor: pointer;
    font-size: 12px;
}

.table-select td {
    padding: 5px;
}

/*Deal Table */

.deal-column {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: clip !important;
}

/*.table-select:hover {*/
/*    cursor: pointer;*/
/*    font-size: 11px;*/
/*}*/

.deals-stage-table {
    width: 100%;
}

.card-body-list-details {
    min-height: 20vh;
    max-height: 20vh;
    padding: 0px !important;
    text-align: center;
    padding-bottom: 100px !important;
    display: table-row !important;
}

/* acception invitations main message */

.invitation-message {
    color: #6d6d6d;
    margin-top: 25vh;
    margin-bottom: 30vh;
    font-size: 20px;
}

.invitation-message p {
    padding-top: 10px;
}

/*
.card-body-deal {
  min-height: 432px;
  max-height: 432px;
  padding: 0px !important;
  text-align: center;
  margin-bottom: 0px;
} */

.table-cell {
    min-width: 130px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: clip !important;
}

.ItemsCard:hover {
    border-color: #3eb7f9 !important;
}

.alliance-members-column {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: clip !important;
}

.space-around {
    margin-left: 5px;
    margin-right: 5px;
}


.link-sign-up{
  color: rgba(0,0,0,0.54)!important;
  text-decoration: underline!important;
  -webkit-text-decoration-color: rgba(0,0,0,0.54)!important;
          text-decoration-color: rgba(0,0,0,0.54)!important;
}

.signUpTerms a{
  color: rgba(0,0,0,0.54)!important;
}
.Toastify__toast-container{
    color: red;
}

.unsupportedDocumentList li{
    list-style: none!important;
    text-align:left;
}
