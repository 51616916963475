.react-tagsinput {
    width: 100%;
    border: 1px solid #CFD7DE;
    border-radius: 5px;
    background-color: #FFFFFF;
    text-align: initial;
    padding: 5px;
    overflow: hidden;
}

.react-tagsinput--focused {
    border-color: #CFD7DE;
}

.react-tagsinput-tag {
    display: inline-block;
    border: 1px solid #D0D7DD;
    border-radius: 20px;
    background-color: #FFFFFF;
    padding: 6px 10px;
    margin: 5px;
}


.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " ×";
}

/*.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 80px;
}*/

.react-tagsinput-input{
    box-sizing: border-box;	height: 36px;
    width: 100%;
    border: none;
    background-color: transparent;
}


.react-tagsinput-input::placeholder{
    color: #A3AFB7;
}
.react-tagsinput-input:focus{
    outline: none;
}
