.pagination-wrapper {
    height: 100%;
    width: auto;
    background: white;
}
.pagination {
    position: relative;
    text-align: center;
    top: 20%;
}
.pagination span{
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 10px;
}
.pagination span.active:before{
    background: #3498db;
}
.pagination span:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    background: white;
    border-radius: 50%;
    border: 1px solid #3498db;

}

.pagination span.active{
    border: none!important;
}