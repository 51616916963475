div.clickable-label {
  height: 100%;
}
div.clickable-label .clickable-icon {
  opacity: 0.4;
  transition: opacity 0.2s;
}
div.clickable-label:hover .clickable-icon {
  opacity: 1;
}

div.comment-label {
  height: 20%;
}
div.comment-label {
  padding-left: 99%;
  margin-top: -5px;
}
div.comment-label img {
  margin-top: 5px;
  width: 12px;
  opacity: 0.4;
  transition: all 0.2s;
  margin-right: 0px;
}
