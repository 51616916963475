span.status-dot {
  margin-right: 3px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
}

span.status-dot.in-progress {
  background-color: rgb(252,189,0);
}

span.status-dot.approved,
span.status-dot.accepted {
  background-color: #70D44B;
}

span.status-dot.rejected {
  background-color: #EB4235;
}

span.status-dot.canceled {
  background-color: lightgrey;
}

span.status-dot.closed {
  background-color: #D0D7DD;
}
span.status-dot.completed {
  background-color: #57c3dd;
}
span.status-dot.new {
  background-color: #70D44B;
}

span.status-dot.pending {
  background-color: #FCBD00;
}

span.status-dot.submitted {
  width: 9px;
  height: 9px;
  background-color: white;
  border: 2px solid rgb(129, 236, 152);
}
