/* #root {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
} */
body {
    background-color: rgb(244, 245, 246);
    font-family: Poppins;
}

.auth0-lock.auth0-lock .auth0-lock-content-wrapper {
    overflow: visible !important;
}

.fsp-picker,
.fsp-picker * {
    transition: none;
}

.fsp-picker-appear-active {
    transition: none;
    opacity: 0;
    display: none;
}

.fsp-picker-appear-to {
    opacity: 0;
    display: none;
}

.fsp-picker-appear {
    opacity: 0;
    display: none;
}

.spacing-td {
    width: 18%;
}

.w-150 {
    width: 150px !important;
}

.float-right {
    position: absolute;
    right: 0;
    margin-right: 2rem;
}

.LoaderMain {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loaderSideBar {
    width: 100%;
    height: 70%;
    overflow: hidden;
    display: flex;
    align-self: center;
    align-content: center;
    align-items: center;
}

.related-table {
    height: 100px;
    overflow-y: scroll;
    position: relative;
}

.justify-center-column div {
    justify-content: center !important;
}

.justify-center-row {
    justify-content: center !important;
}

.positionBottonRight {
    float: right;
}

.positionBottonClose {
    right: 15px;
    position: absolute;
}

.heightBodyCard {
    min-height: 250px;
    max-height: 55vh;
}

.minHeightCard {
    height: 94%;
}

.button-list-view {
    position: absolute;
    bottom: 3rem;
    left: 48%;
    margin-left: -50px;
    opacity: 1;
}

.radius {
    border-radius: 50px;
}

.company-icons {
    display: flex;
}

.top-icons {
    display: flex;
}

.more-icon {
    color: #7e828b;
}

.button-top {
    top: 11px;
}

.action-button-top {
    color: #878c93 !important;
    border-color: transparent !important;
}

.action-link-button {
    position: absolute;
    bottom: 2rem;
    opacity: 1;
    border-radius: 50px;
    left: 47%;
    margin-left: -52px;
}

.collaborate-button {
    border-color: transparent !important;
}

.collaborate-button-text {
    font-weight: normal;
    color: #878c93;
    font-size: 14px;
}

.card-body {
    min-height: 54vh;
    max-height: 54vh;
    padding: 0px;
    text-align: center;
}

.card-body-report {
    min-height: 66vh;
    padding: 0px;
    text-align: center;
    max-height: 66vh;
}

.card-body-report-with-footer {
    min-height: 61vh;
    padding: 0px;
    text-align: center;
    max-height: 61vh;
}

.card-body-principal {
    min-height: 63vh;
    max-height: 63vh;
    padding: 0px !important;
    text-align: center;
    margin-bottom: 0px;
    overflow: hidden !important;
}

.card-body-deal {
    min-height: 77vh;
    max-height: 77vh;
    padding: 0px !important;
    text-align: center;
    margin-bottom: 0px;
    overflow-y: hidden !important;
}

.card-body-list {
    min-height: 54vh;
    max-height: 54vh;
    padding: 0px !important;
    text-align: center;
    padding-bottom: 100px !important;
    display: table-row !important;
    overflow-x: hidden !important;
}

.card-body-full {
    min-height: 54vh;
    max-height: 58vh;
    padding: 0px !important;
    text-align: center;
    padding-bottom: 100px !important;
    display: table-row !important;
    overflow-x: hidden !important;
}

.card-body-detail {
    min-height: 54vh;
    max-height: 54vh;
    padding: 0px !important;
    text-align: center;
    margin-bottom: 70px;
}

.deal-list-view {
    width: 84.6vw;
}

.list-box {
    margin-right: 5px;
}

.onBoardingItem {
    border: 1px solid #fff;
    height: 105px;
    padding-top: 10px;
    width: 385px;
    margin-bottom: 5px;
    margin-left: 4px;
    margin-right: 4px;
}

.onboarding-name {
    color: #3EB7F9;
    font-size: 16px;
    font-weight: 700;
}

.onboarding-name:visited {
    color: #3EB7F9;

}

.onboarding-text {
    font-size: 16px;
}

.pie-legend {
    margin-left: 37px;
}

.pie-legend-text {
    font-size: 16px;
    padding-left: 10px;
    font-weight: 500;
}

.pie-legend span {
    width: 12px !important;
    height: 12px !important;
    margin-right: -10px;
}

.pie-title {
    font-size: 22px;
    font-weight: 500;
    margin-left: 10px !important;
    text-align: center;
}

.pie-graph {
    height: 250px;
    width: 400px;
}

.items-card {
    width: 84.5vw !important;
    overflow-x: hidden;
}

.items-table {
    overflow-x: scroll;
    padding: 0 !important;
}

.search-input input {
    width: 455px;
}

.search-input img {
    margin-right: 15px;
}


/* Item Tables Design  */

@media (max-height: 850px) and (width: 1400px) {
    .items-card {
        width: 79.5vw !important;
        overflow: hidden;

    }
}

@media (min-height: 850px) and (width: 1400px) {
    .items-card {
        width: 78.9vw !important;
        overflow: hidden;

    }

    .pie-graph {
        height: 250px !important;
        width: 350px !important;
    }
}

@media (min-height: 750px) and (width: 1440px) {
    .items-card {
        width: 79.4vw !important;
        overflow-x: hidden;

    }

    .pie-graph {
        height: 200px;
        width: 350px;
    }
}

@media (min-height: 800px) and (width: 1600px) {
    .items-card {
        width: 82.3vw !important;
        overflow: hidden;

    }
}

@media (min-height: 789px) and (width: 1600px) {
    .items-card {
        width: 81.5vw !important;
        overflow: hidden;

    }
}

@media (min-height: 900px) and (width: 1680px) {
    .items-card {
        width: 82.4vw !important;
        overflow: hidden;

    }
}

@media (max-height: 900px) and (width: 1366px) {
    .card-body-deal {
        min-height: 74vh;
        max-height: 74vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
    }

    .card-body-list {
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
        overflow-y: scroll !important;
    }

    .deal-list-view {
        width: 79.2vw;
    }

    .pie-legend {
        width: 20vw;
        margin-left: 3px;
    }

    .pie-legend-text {
        font-size: 15px;
        font-weight: 500;
    }

    .pie-legend span {
        width: 12px !important;
        height: 12px !important;
        margin-right: 1px !important;
    }

    .pie-graph {
        height: 200px !important;
        width: 350px !important;
    }

    .items-card {
        width: 81.5vw !important;
        overflow: hidden;

    }

    .items-table {
        overflow: auto;
        overflow-y: hidden !important;
        height: 64vh;
    }
}

@media (max-height: 768px) and (min-width: 1360px) {
    .card-body-list {
        min-height: 50vh !important;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }
}

@media (max-height: 660px) and (min-width: 1360px) {
    .card-body-deal {
        min-height: 68vh;
        max-height: 68vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
    }

    .deal-list-view {
        width: 78vw;
    }

    .card-body-list {
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
        overflow-y: auto !important;
    }

    .items-card {
        width: 78.3vw !important;
        height: 80vh;
        overflow: hidden;
    }

    .items-table {
        overflow: auto;
        overflow-y: hidden !important;
        height: 69vh;
    }

}

@media (max-height: 700px) and (width: 1280px) {
    .items-card {
        width: 76.8vw !important;
        height: 80vh;
        overflow: hidden;
    }
}

@media (max-width: 1280px) {
    .items-card {
        width: 76.9vw !important;
        overflow: hidden;
    }

    .card-body-list {
        min-height: 59vh;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }

}

@media (max-width: 1280px) and (min-height: 650px) {
    .card-body-list {
        min-height: 50vh;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }
}

@media (max-width: 1280px) and (min-height: 800px) {

    .items-card {
        width: 76.9vw !important;
        overflow: hidden;
    }

    .card-body-list {
        min-height: 56.8vh;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }

    .pie-graph {
        height: 250px !important;
        width: 330px !important;
    }

    .pie-legend-text {
        font-size: 13px !important;
    }

}

@media (max-width: 1200px) and (max-height: 760px) {
    .items-card {
        width: 74.3vw !important;
        overflow: hidden;
    }
}

@media (max-width: 1024px) and (max-height: 660px) {
    .items-card {
        width: 71.2vw !important;
        overflow: hidden;
    }
}

@media (max-height: 960px) {
    .deal-list-view {
        width: 79.5vw;
    }

    .card-body-list {
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
        overflow-y: scroll !important;
        min-height: 43vh;
    }

    .pie-legend {
        margin-left: 10px !important;
    }

    .pie-legend-text {
        font-size: 15px;
        font-weight: 500;
    }

    .pie-legend span {
        width: 10px !important;
        height: 10px !important;

    }


    .items-table {
        overflow: auto;
        overflow-y: hidden !important;
        height: 64vh;
    }

}

@media (max-height: 768px) {
    .card-body-deal {
        min-height: 70vh;
        max-height: 70vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
    }

    .deal-list-view {
        width: 79vw;
    }

    .onboarding-name {
        color: #3EB7F9;
        font-size: 15px;
        font-weight: 700;
    }

    .onboarding-name:visited {
        color: #3EB7F9;

    }

    .onboarding-text {
        font-size: 14px;
    }

    .pie-legend {
        margin-left: 5px !important;
    }

    .pie-legend-text {
        font-size: 12px;
        padding-left: 0px;
        font-weight: 500;
    }

    .pie-legend span {
        width: 8px !important;
        height: 8px !important;
        margin-right: 1px !important;
    }

    .pie-graph {
        height: 180px !important;
        width: 330px !important;
    }

    .table-cell {
        min-width: 120px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: clip !important;
    }

    .items-card {
        height: 80vh;
        overflow: hidden;
    }

    .items-table {
        overflow: auto;
        overflow-y: hidden !important;
        height: 60vh;
    }

}

@media (max-height: 650px) {
    .deal-list-view {
        width: 76.4vw;
    }

    .items-card {
        width: 76.9vw !important;
        height: 80vh;
        overflow-x: hidden;
    }

    .items-table {
        overflow: auto;
        overflow-y: hidden !important;
        height: 69vh;
    }
}

@media (min-height: 610px) {
    .card-body {
        min-height: 54vh;
        text-align: center;
        max-height: 54vh;
    }

    .card-body-report {
        min-height: 75vh;
        text-align: center;
        max-height: 75vh;
    }

    .card-body-report-with-footer {
        min-height: 70vh;
        text-align: center;
        max-height: 70vh;
    }

    .card-body-detail {
        min-height: 60vh;
        text-align: center;
    }

    .card-body-principal {
        min-height: 62vh;
        max-height: 62vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
        overflow: hidden !important;
    }


    .button-list-view {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        margin-left: -50px;
        opacity: 1;
    }
}

.pg-viewer-wrapper {
    height: 430px;
}

.icon-download {
    position: absolute;
    top: 10px;
    right: 36px;
}

.file-icon {
    height: 45px;
    display: flex;
    padding: 6px;
    width: 214px;
    border: 1px solid #e9eff4;
    margin-top: 5px;
}

.file-icon:hover {
    background-color: lightgrey;
}

@media (min-height: 700px) and (min-width: 720px) {
    .card-body {
        min-height: 60vh;
        text-align: center;
        max-height: 60vh;
    }

    .card-body-report {
        min-height: 68vh;
        text-align: center;
        max-height: 68vh;
    }

    .card-body-report-with-footer {
        min-height: 63vh;
        text-align: center;
        max-height: 63vh;
    }

    .card-body-detail {
        min-height: 62vh;
        text-align: center;
    }

    .card-body-principal {
        min-height: 68vh;
        max-height: 68vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
        overflow: hidden !important;
    }

    .card-body-list {
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 40px !important;
    }

    .heightBodyCard {
        min-height: 59vh;
        max-height: 59vh;
    }

    .button-list-view {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        margin-left: -50px;
        opacity: 1;
    }
}

@media (min-height: 768px) and (min-width: 1360px) {
    .card-body {
        min-height: 62vh;
        text-align: center;
        max-height: 62vh;
    }

    .card-body-report {
        min-height: 70vh;
        text-align: center;
        max-height: 70vh;
    }

    .card-body-report-with-footer {
        min-height: 65vh;
        text-align: center;
        max-height: 65vh;
    }

    .card-body-detail {
        min-height: 64vh;
        text-align: center;
    }

    .card-body-principal {
        min-height: 73vh;
        max-height: 73vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
        overflow: hidden !important;
    }

    .card-body-list {
        min-height: 56vh;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }

    .heightBodyCard {
        min-height: 62vh;
        max-height: 62vh;
    }

    .button-list-view {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        margin-left: -50px;
        opacity: 1;
    }

}

@media (min-height: 800px)  and (min-width: 1360px) {
    .card-body {
        min-height: 65vh;
        text-align: center;
        max-height: 65vh;
    }

    .card-body-report {
        min-height: 68vh;
        text-align: center;
        max-height: 68vh;
    }

    .card-body-report-with-footer {
        min-height: 59vh;
        text-align: center;
        max-height: 61vh;
    }

    .card-body-detail {
        min-height: 64vh;
        text-align: center;
    }

    .card-body-principal {
        min-height: 72vh;
        max-height: 73vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
        overflow: hidden !important;
    }

    .card-body-list {
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }

    .heightBodyCard {
        min-height: 62vh;
        max-height: 62vh;
    }

    .button-list-view {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        margin-left: -50px;
        opacity: 1;
    }
}

@media (min-height: 900px)  and (min-width: 1360px) {
    .card-body {
        min-height: 67vh;
        text-align: center;
        max-height: 67vh;
    }

    .card-body-report {
        min-height: 75vh;
        text-align: center;
        max-height: 75vh;
    }

    .card-body-report-with-footer {
        min-height: 69vh;
        text-align: center;
        max-height: 69vh;
    }

    .card-body-detail {
        min-height: 69vh;
        text-align: center;
    }

    .card-body-list {
        min-height: 57.1vh;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 80px !important;
    }

    .card-body-principal {
        min-height: 77vh;
        max-height: 77vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
        overflow: hidden !important;
    }

    .heightBodyCard {
        min-height: 68vh;
        max-height: 68vh;
    }
}


@media (min-height: 1200px) {
    .card-body {
        min-height: 75vh;
        text-align: center;
        max-height: 75vh;
    }

    .card-body-report {
        min-height: 83vh;
        text-align: center;
        max-height: 83vh;
    }

    .card-body-report-with-footer {
        min-height: 78vh;
        text-align: center;
        max-height: 78vh;
    }

    .card-body-detail {
        min-height: 77vh;
        text-align: center;
    }

    .card-body-principal {
        min-height: 81vh;
        max-height: 81vh;
        padding: 0px !important;
        text-align: center;
        margin-bottom: 0px;
        overflow: hidden !important;
    }

    .card-body-list {
        min-height: 78vh;
        max-height: 78vh;
        padding: 0px !important;
        text-align: center;
        display: table-row !important;
        padding-bottom: 150px !important;
    }

    .heightBodyCard {
        min-height: 74vh;
        max-height: 74vh;
    }
}

.cvs-input {
    margin: 0.5em;
}

.cvs-error {
    border: 1px solid red;
    border-radius: 5px;
}

.CommentInfoUser {
    position: absolute;
    top: 15px;
    left: 65px;
}

.CommentOwnerText {
    text-align: right;
}

.CommentText {
    max-width: 240px;
    min-height: 20px;
    height: auto;
    min-width: 80px;
    display: inline-block;
    background-color: #eceff1;
    padding: 6px 12px;
    padding-bottom: 0px;
    border-radius: 5px;
    color: #47525d;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    margin-top: 12px;
}


.pie-legend span {
    width: 18px !important;
    height: 18px !important;
    margin-top: 1px;
    margin-right: -20px;
}


.companyHeaderButton:hover {
    background-color: #f3f3f3;
}

.table-header-cell {
    color: #7e828b;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 18px;
    text-transform: capitalize;
    background-color: #f4f7f9;
}

.gray-border {
    border: 2px solid #f4f7f9;
}

.table-select {
    cursor: pointer;
    font-size: 12px;
}

.table-select td {
    padding: 5px;
}

/*Deal Table */

.deal-column {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: clip !important;
}

/*.table-select:hover {*/
/*    cursor: pointer;*/
/*    font-size: 11px;*/
/*}*/

.deals-stage-table {
    width: 100%;
}

.card-body-list-details {
    min-height: 20vh;
    max-height: 20vh;
    padding: 0px !important;
    text-align: center;
    padding-bottom: 100px !important;
    display: table-row !important;
}

/* acception invitations main message */

.invitation-message {
    color: #6d6d6d;
    margin-top: 25vh;
    margin-bottom: 30vh;
    font-size: 20px;
}

.invitation-message p {
    padding-top: 10px;
}

/*
.card-body-deal {
  min-height: 432px;
  max-height: 432px;
  padding: 0px !important;
  text-align: center;
  margin-bottom: 0px;
} */

.table-cell {
    min-width: 130px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: clip !important;
}

.ItemsCard:hover {
    border-color: #3eb7f9 !important;
}

.alliance-members-column {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: clip !important;
}

.space-around {
    margin-left: 5px;
    margin-right: 5px;
}


.link-sign-up{
  color: rgba(0,0,0,0.54)!important;
  text-decoration: underline!important;
  text-decoration-color: rgba(0,0,0,0.54)!important;
}

.signUpTerms a{
  color: rgba(0,0,0,0.54)!important;
}
.Toastify__toast-container{
    color: red;
}

.unsupportedDocumentList li{
    list-style: none!important;
    text-align:left;
}