/* Detail Table */
table.details {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
}

table.details td,
table.details th {
    border: 1px solid rgb(241, 241, 241);
    border-top: none;
    border-left: none;
    padding: 25px;
}

table.details tr:nth-child(even) {
    /* background-color: #f2f2f2; */
}

table.details th {
    color: black;
    width: 28%;
    font-weight: 600;
    font-size: 1.4rem;
}

table.details th:nth-child(even) {
    /* background-color: #fafafa; */
}

/*Alliance Create/Edit*/
span.btn-invite button {
    color: #3eb7f9;
    border: 1px solid #3eb7f9;
    transform: translateY(25px);
    font-size: 1.4rem;
    padding: 0 2.5rem;
}

div.divider {
    margin: 55px 0px 0px 0px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 0px;
}

div.spacing {
    margin-top: 10px; 
}

/* Company Details/Edit */
.company-icons {
    position: absolute;
    right: 10px;
    display: flex;
}

.company-icons button {
    padding: 0 2rem;
    margin: 0px 5px;
}

div.company-icons #btn-leave-company button:hover {
    color: rgb(224, 57, 57) !important;
    border-color: rgb(224, 57, 57) !important;
}

div.company-icons #btn-approve button:hover {
    color: rgb(49, 192, 92) !important;
    border-color: rgb(49, 192, 92) !important;
}

/* Alliance List */

a.item-name {
    color: #3EB7F9;
}

a.item-name:hover {
    color: #3EB7F9;
}

a.item-name:visited {
    color:#3EB7F9;
}
