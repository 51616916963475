span.file-icon {
  /* cursor: pointer; until something happens*/
  cursor: default;
  padding: 6px 11px 8px 11px;
  margin-left: -11px;
  border-radius: 4px;
  transition: all 0.2s;
  display: block;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 148px;
}
span.file-icon:hover {
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(248, 248, 248, 0.301); */
}

.file-icon > img {
  margin-right: 5px;
}

div.close-icon {
  width: 26px;
  height: 26px;
  background-size: "contain";
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/icons/close-option.svg");
  margin-top: 22px;
  transform: translateY(-22px);
  transition: all 0.2s;
}

div.close-icon:hover {
  width: 26px;
  height: 26px;
  background-size: "contain";
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/icons/close-option.svg");
  margin-top: 22px;
  transform: translateY(-22px);
  transition: all 0.2s;
  cursor: pointer;
}