a:visited{
  color:#323C47;
}
body {
  overflow-x: hidden;
}
div.progress-icon {
  width: 26px;
  height: 26px;
  background-size: "contain";
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../images/icons/empty-circle.svg");
  margin-top: 20px;
  transform: translateY(-22px);
  transition: all 0.2s;
}
div.progress-icon::after {
  content: "";
  height: 25px;
  width: 2px;
  display: block;
  position: relative;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f2f2f2;
}
div.progress-icon.checked {
  background-image: url("../../../images/icons/check-mark-outlined.svg");
}
div.progress-icon.checked::after {
  background-color: #3eb7f9;
}

div.progress-icon.active {
  background-image: url("../../../images/icons/right-arrow-solid.svg");
}

div.progress-icon.last {
  margin-bottom: 25px;
}

div.progress-icon.last::after {
  height: 0;
}

div.progress-sec-name {
  text-align: left;
  width: 201px;
  opacity: 0.9;
  color: #d0d7dd;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  /*padding-top: 6px;*/
  padding-bottom: 24px;
  margin: 0;
  transition: all 0.3s;
  cursor: pointer;
  margin-top: 2px;
}
div.progress-sec-name.active {
  color: #323c47;
}

/* Progress Bar Body */
div.progress-bar {
  width: 70%;
  height: 11px;
  border-radius: 17px;
  background-color: #e9eff4;
  margin: auto;
  padding-right: 20px;
}

/* Progress Bar Color Fill*/
div.progress-bar::before {
  content: "";
  display: block;
  height: 11px;
  border-radius: 17px;
  background-color: #3eb7f9;
  transition: all 1.5s;
}

/* Progress Bar Number Value*/
div.progress-bar::after {
  color: #3eb7f9;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  display: block;
  position: relative;
  right: -115%;
  top: -11px;
  transition: all 1.5s;
}

/* Progress Bar % Values */
/* 0% */
div.progress-bar.p0::before {
  width: 0%;
}
div.progress-bar.p0::after {
  content: "0%";
}

/* 20% */
div.progress-bar.p20::before {
  width: 20%;
}
div.progress-bar.p20::after {
  content: "20%";
}

/* 40% */
div.progress-bar.p40::before {
  width: 40%;
}
div.progress-bar.p40::after {
  content: "40%";
}

/* 60% */
div.progress-bar.p60::before {
  width: 65%;
}
div.progress-bar.p60::after {
  content: "60%";
}

/* 80% */
div.progress-bar.p80::before {
  width: 85%;
}
div.progress-bar.p80::after {
  content: "80%";
}

/* 100% */
div.progress-bar.p100::before {
  width: 103.5%;
}
div.progress-bar.p100::after {
  content: "100%";
}

/* Makind label of "Last" name dissapear */
div.label-gone .css-jigfdj {
  opacity: 0;
}

.plus-circle-icon {
  width: 18px;
  cursor: pointer;
}

div.add-hover {
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.2s;
}

div.add-hover:hover {
  opacity: 1;
}

button {
  float: right;
}

/* button:nth-child(2) {
  margin-right: 15px;
} */

span.ticker {
  opacity: 0;
  margin-top: 15px;
  transition: all 0.3s;
}
span.ticker input{
  text-transform: uppercase;
  font-weight: 500;
  width: 50%;
}

span.ticker input::placeholder{
  text-transform: capitalize;
}
span.ticker.is-visible {
  opacity: 1;
}
span.ticker input {
  border-style: dashed;
}

textarea::-webkit-input-placeholder {
  color: #d3d8de;
}

textarea:-ms-input-placeholder {
  color: #d3d8de;
}

textarea::placeholder {
  color: #d3d8de;
}

.users-divider::after {
  content: "";
  height: 1px;
  width: 100%;
  display: inline-block;
  position: relative;
  top: 19px;
  left: 50%;
  transform: translateX(-49%);
  background-color: #cfd7dd;
  margin-bottom: 30px;
}
