/* initiative main create */
/* main */
.wrapperForm {
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
  }
  .mainForm {
    width: 70% !important;
    height: 70vh !important; 
    overflow: auto !important;
  }
  .sidebarForm {
    width: 30%;
    height: 45vh !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  
  /* footer */
.footerCard{
  display:flex ;
  flex-direction: row ;
  justify-content: space-between ;
}

.footerCardRight{
  display:flex ;
  flex-direction: row ;
  justify-content: space-around; 
}

  /* fileInput */
  .fileInput {
    color: #E9EFF4 !important;
    font-weight: lighter;
    background-color: white;
  }

  /* businessCaseForm */
  /* Costs & Revenue
  margin */
  .widthField{
    width: 50% !important;
  }
  .spaceForm{
    margin-top: 8px !important;
  }

  /* progressLeft */

  @media( max-width: 1100px) {
    .sizeProgress {
      font-size: 10px !important;
      padding: 10px !important;
    }
  }

.opacityButton{
  opacity: 0.7;
}
.fontWeighButton{
  font-weight: bold !important;
}
